import React, {useState} from "react"
import Container from "../ui/Container"
import Title from "../ui/Title"
import CloseModal from "../ui/CloseModal"
import BureauImage from "../images/BureauImage"
import HallBasImage from "../images/HallBasImage"
import PalierImage from "../images/PalierImage"
import CarousselImage from "../images/CarousselImage"
import SalleImage from "../images/SalleImage"
import WellnessImage from "../images/WellnessImage"
import Arrow from "../../assets/vectors/arrow-down.svg"
import HallBannerOriginalImage from "../images/HallBannerOriginalImage"
import JapanImage from "../images/JapanImage"
import BuildingTerImage from "../images/BuildingTerImage"

const Galery = () => {
    const [zoomed,setZoomed] = useState(null)
    const photos = [
        BuildingTerImage,
        HallBasImage,
        //EntreeImage,
        //PatioImage,
        //HallImage,
        HallBannerOriginalImage,
        BureauImage,
        PalierImage,
        JapanImage,
        CarousselImage,
        SalleImage,
        WellnessImage
    ]

    return (
        <div className={"relative h-full py-8 md:py-12 bg-gray-300"}>
            <Container css={"flex flex-col justify-center h-full space-y-6"}>
                <Title size={2}>
                    Galerie photos
                </Title>
                <div className={[
                        "relative grid gap-4 md:grid-cols-3 md:gap-6",
                        (zoomed === null ? "overflow-y-auto" : zoomed)
                    ].join(' ')}
                >
                    {photos.map((item,i) => {
                        const Render = item
                        return (
                            <button className={[
                                        "cursor-pointer pb-2/3 transform transition hover:shadow-lg focus:outline-none",
                                        (zoomed === i ? "absolute z-60 w-full left-0 top-1/2 -translate-y-1/2 md:translate-y-0 md:top-0 md:pb-0 md:h-full" : "relative hover:-translate-y-px")
                                    ].join(' ')}
                                    onClick={() => setZoomed(i)}
                                    key={i}
                            >
                                <div className={"absolute inset-0 object-cover"}>
                                    <Render className={"cursor-pointer"} />
                                    <span className={"sr-only"}>Voir en plus grand</span>
                                </div>
                            </button>
                        )
                    })}
                </div>
            </Container>
            <div className={zoomed !== null ? "h-full w-full" : "hidden"}>
                <div data-zoomed={zoomed} className={"absolute inset-0 bg-black bg-opacity-75 z-50"}>
                    <CloseModal clickHandler={() => setZoomed(null)} />
                </div>
                <button className={"absolute z-60 bg-black bg-opacity-50 rounded-full p-2 pb-1 w-12 h-12 transform transition-all -rotate-90 right-2 top-1/2 lg:bg-transparent hover:translate-x-1 focus:outline-none"}
                        onClick={() => setZoomed(zoomed < (photos.length - 1) ? (zoomed + 1) : 0 )}
                >
                    <Arrow className={"text-white fill-current text-shadow h-8 w-8"} />
                    <span className={"sr-only"}>Image suivante</span>
                </button>
                <button className={"absolute z-60 bg-black bg-opacity-50 rounded-full p-2 pb-1 w-12 h-12 transform transition-all rotate-90 left-2 top-1/2 lg:bg-transparent hover:-translate-x-1 focus:outline-none"}
                        onClick={() => setZoomed(zoomed > 0 ? (zoomed - 1) : (photos.length - 1))}
                >
                    <Arrow className={"text-white fill-current text-shadow h-8 w-8"} />
                    <span className={"sr-only"}>Image précédente</span>
                </button>
            </div>
        </div>
    )
}

export default Galery

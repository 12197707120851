import React from "react"
import PropTypes from "prop-types"

const Title = ({ ...props }) => {
    const Level = `h${ props.level }`
    const sizeMap = [
        'text-8xl md:text-9xl tracking-tighter',
        'text-5xl md:text-7xl tracking-tight',
        'text-3xl md:text-5xl',
        'text-xl md:text-3xl',
        'text-lg text-xl'
    ]
    const computeSize = sizeMap[props.size] ? sizeMap[props.size] : sizeMap[0]

    const colorMap = {
        white: 'text-white',
        primary: 'text-primary',
        gray: 'text-gray-600',
        black: 'text-black',
    }
    const computeColor = colorMap[props.color] ? colorMap[props.color] : colorMap.black

    return (
        <header className={props.css} style={props.style}>
            <Level className={`${computeSize} ${computeColor} block uppercase font-title font-bold`}>
                { props.children }
            </Level>
        </header>
    )
}

Title.defaultProps = {
    level: 2,
    size: 0,
    color: `black`,
    css: ``,
    style: {}
}

Title.propTypes = {
    children: PropTypes.node.isRequired,
    level: PropTypes.number,
    size: PropTypes.number,
    color: PropTypes.string,
    css: PropTypes.string,
    style: PropTypes.object
}

export default Title

import React, {useEffect, useState} from "react"
import { Link } from "gatsby"
import data from "../../../../content/nav/main.json"
import {animated, useTrail} from "react-spring"

const NavbarLinks = (props) => {
    const linkCss = "text-base md:text-sm block transition group focus:outline-none"
    const addLinkCss = props.css ? props.css : ''
    const items = data

    const [responsiveNavbarOpen, setResponsiveNavbarOpen] = useState(false)

    useEffect(() => {
        function handleNavbarState() {
            const mainNavbar = document.getElementById('mainNavbar')
            const navStyle = window.getComputedStyle(mainNavbar, null).flexDirection
            if( navStyle === 'row' ) {
                setResponsiveNavbarOpen(true)
            }
        }
        handleNavbarState()
        window.addEventListener('resize', handleNavbarState)
        return () => (
            window.removeEventListener('resize', handleNavbarState)
        )
    }, [setResponsiveNavbarOpen])

    const trail = useTrail(items.length, {
        opacity: props.navbarOpen || props.mobileNavbarOpen || responsiveNavbarOpen ? 1 : 0,
        transform: props.navbarOpen || props.mobileNavbarOpen || responsiveNavbarOpen ? 'translateY(0)' : 'translateY(-0.5rem)',
        from: { opacity: 0, transform: 'translateY(-0.5rem)' },
    })

    return (
        <ul id="mainNavbar" className={"flex flex-col items-end space-y-6 md:space-y-0 md:flex-row md:items-center"}>
            {trail.map((style, index) => {
                const link = items[index]
                return (
                    <animated.li key={index} style={style}>
                        <Link
                            className={[linkCss, addLinkCss].join(' ')}
                            to={link.to}
                            data-target={link.to.replace('#', '')}
                            onClick={() => props.clickHandler(link.to.replace('#', ''))}
                        >
                            {link.name}
                            <span className={"block w-full relative"}>
                                <span className={"absolute -bottom-1 left-0 w-full h-0 transform -translate-y-1 bg-primary transition-all group-hover:h-1 group-hover:translate-y-0"} />
                            </span>
                        </Link>
                    </animated.li>
                )
            })}
        </ul>
    )
}

export default NavbarLinks

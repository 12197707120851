import React from "react"
import data from "../../../../content/main.json"
import useModal from "../../../hooks/useModal"
import Legal from "../../content/Legal"
import Modal from "../../ui/Modal"

const CookiePopin = ({dispatch}) => {
    const {isShowing, toggle} = useModal()

    return (
        <>
            <aside
                className={"fixed z-100 bottom-0 right-0 md:right-4 md:bottom-4 max-w-screen-xl bg-primary text-white py-4 px-6 flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 md:items-center"}
            >
                <p className={"text-xs leading-relaxed"}>
                    {data.consent?.content}
                    {" "}
                    <button onClick={toggle} className={"hover:underline"}>
                        <strong>{data.consent?.more}</strong>
                    </button>
                </p>
                <span className={"flex flex-row space-x-2 flex-shrink-0"}>
                    <button
                        className={"text-center text-xs font-bold uppercase border-2 border-white px-3 py-2 leading-none hover:text-primary hover:bg-white"}
                        onClick={() => dispatch({type: 'acceptAll'})}
                    >
                        {data.consent?.accept}
                    </button>
                    <button
                        className={"text-center text-xs font-bold uppercase border-2 border-white px-3 py-2 leading-none hover:text-primary hover:bg-white"}
                        onClick={() => dispatch({type: 'declineAll'})}
                    >
                        {data.consent?.decline}
                    </button>
                    <button
                        className={"text-center text-xs font-bold uppercase border-2 border-white px-3 py-2 leading-none hover:text-primary hover:bg-white"}
                        onClick={() => dispatch({type: 'showManageCookiePopup'})}
                    >
                        {data.consent?.manage}
                    </button>
                </span>
            </aside>
            <Modal isShowing={isShowing} hide={toggle}>
                <Legal />
            </Modal>
        </>
    )
}

export default CookiePopin

import React, {useState} from "react"
import NavbarLinks from "./NavbarLinks"
import NavbarIcons from "./NavbarIcons"
import Logo from '../../../assets/vectors/A.svg'
import NavbarToggle from "./NavbarToggle"

const Navbar = () => {
    const [navbarOpen] = useState(true)
    const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false)

    function handleClick(e) {
        const element = document.getElementById(e)
        if( element ) {
            element.scrollIntoView()
        }
        setMobileNavbarOpen(false)
    }

    function handleToggleClick() {
        setMobileNavbarOpen(!mobileNavbarOpen)
    }

    return (
        <div className={"flex items-start h-0 px-8"}>
            <div className={"flex -ml-16 -mt-6"}>
                <a href={"/"} className={"relative z-50 p-8 pt-12 pl-12 rounded-full shadow-md bg-white"}>
                    <Logo
                        className={[
                          "w-8 h-8 md:w-14 md:h-14 fill-current cursor-pointer",
                          "text-black hover:text-primary"
                        ].join(' ')}
                    />
                    <span className={"sr-only"}>Accueil Australia</span>
                </a>
                <nav className={"self-center pl-2"}>
                    <NavbarIcons navbarOpen={navbarOpen} />
                </nav>
            </div>
            <NavbarToggle
                navbarOpen={navbarOpen}
                mobileNavbarOpen={mobileNavbarOpen}
                clickHandler={() => handleToggleClick()}
            />
            <span
                data-navbaropen={navbarOpen}
                data-mobilenavbaropen={mobileNavbarOpen}
                className={["md:ml-auto -mr-8 md:mr-0 md:block", (mobileNavbarOpen ? "" : "hidden") ].join(' ')}
            >
                <nav
                    id="mainNav"
                    className={[
                        (navbarOpen || mobileNavbarOpen ? "translate-y-0 mt-4" : "-translate-y-full -mt-6"),
                        "transform transition-all",
                        "leading-none text-sm uppercase font-title font-bold",
                        "md:bg-white md:shadow-md md:rounded-3xl md:px-2",
                    ].join(' ')}
                >
                    <NavbarLinks
                        css="bg-white md:bg-transparent shadow md:shadow-none rounded-l-full md:rounded-none py-3 pl-8 pr-6 md:px-4 hover:text-primary"
                        navbarOpen={navbarOpen}
                        mobileNavbarOpen={mobileNavbarOpen}
                        clickHandler={(e) => handleClick(e)}
                    />
                </nav>
            </span>
        </div>
    )
}

export default Navbar

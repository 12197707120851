import React from "react"

const NavbarToggle = (props) => (
    <span className={[
        "flex mt-2 justify-end ml-auto md:hidden",
        ( props.mobileNavbarOpen ? "mr-6" : "-mr-8" )
    ].join(' ')}>
        <button className={[
                    "bg-white shadow py-2 focus:outline-none",
                    props.mobileNavbarOpen ? 'px-2 mt-1 rounded-full' : 'pl-6 pr-5 rounded-l-full'
                ].join(' ')}
                onClick={() => props.clickHandler()}
                type="button"
        >
            <svg className="w-10 text-black hover:text-primary" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd"
                      className={props.mobileNavbarOpen ? 'hidden' : 'block'}
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                />
                <path fillRule="evenodd"
                      className={props.mobileNavbarOpen ? 'block' : 'hidden'}
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                />
            </svg>
        </button>
    </span>
)

export default NavbarToggle

import React from "react"
import Navbar from "./nav/Navbar"

const Header = () => (
    <header id="app-header" className={"fixed w-full left-0 top-0 z-40 md:z-80"}>
        <Navbar />
    </header>
)

export default Header

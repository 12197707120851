import data from "../../../content/contact.json"
import Title from "../ui/Title"
import React from "react"
import Australia from "../../assets/vectors/australia.svg"
import Container from "../ui/Container"
import WindowsImage from "../images/WindowsImage"

const Contact = () => {
    const Logos = [["/images/logo-cbre.png", null], ["/images/logo-bnp.png", null], ["/images/unofi-logo.svg", "/images/logo-colliers.png"]]
    return (
        <>
            <div className={"absolute inset-0 object-cover"}>
                <WindowsImage />
            </div>
            <Container css={"relative h-full py-16 md:py-24"}>
                <div className={"bg-white h-full overflow-y-auto shadow-lg p-8 space-y-12 bg-opacity-75"}>
                    <Australia className={"fill-current w-full md:w-auto md:h-10"} />
                    <Title size={2}>{data.title}</Title>
                    <div className={"flex flex-col space-y-6 md:flex-row md:space-y-0 pb-8"}>
                        {data.contacts.map((item,i) => (
                            <div key={i} className={"space-y-12 md:flex-1 md:text-lg"}>
                                {item.map((el,ii) => {
                                    const logoSrc = Logos[i][ii] || ""
                                    return (
                                        <aside style={{minHeight: 104}} key={ii}>
                                            {logoSrc &&
                                                <figure className={"h-20 w-64 py-3 mb-1"}>
                                                    <img src={logoSrc} loading={"lazy"} alt="" className={"h-full"} />
                                                </figure>
                                            }
                                            {el.name &&
                                                <p className={"font-title font-bold text-lg md:text-xl"}>
                                                    {el.name}
                                                </p>
                                            }
                                            {el.tel &&
                                                <div className={"text-base md:text-lg"}>
                                                    {el.tel}
                                                </div>
                                            }
                                            {el.mobile &&
                                                <div className={"text-base md:text-lg"}>
                                                    {el.mobile}
                                                </div>
                                            }
                                            <div className={"underline font-medium text-xs md:text-sm"}>
                                                {el.email}
                                            </div>
                                        </aside>
                                    )
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Contact

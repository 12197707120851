import data from "../../../content/legal.json"
import Title from "../ui/Title"
import React from "react"
import Australia from "../../assets/vectors/australia.svg"
import Container from "../ui/Container"
import WindowsImage from "../images/WindowsImage"

const Legal = () => (
    <>
        <div className={"absolute inset-0 object-cover"}>
            <WindowsImage />
        </div>
        <Container css={"relative h-full py-16 md:py-24"}>
            <div className={"bg-white h-full overflow-y-auto shadow-lg p-8 text-gray-600 space-y-12 bg-opacity-75"}>
                <Australia className={"fill-current w-full md:w-auto md:h-10"} />
                <Title size={2} color={"gray"}>{data.title}</Title>
                <div className={"space-y-6 pb-8"}>
                    {data.sections.map((item,i) => (
                        <div key={i} className={"space-y-2"}>
                            <p className={"font-title font-bold uppercase sm:text-lg"}>
                                {item.title}
                            </p>
                            <div className={"text-xs sm:text-base space-y-2"}>
                                {item.content.split("\n").map((i,key) => {
                                    return <p key={key}>{i}</p>
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    </>
)

export default Legal

import Plus from "../../assets/vectors/plus.svg"
import React from "react"
import PropTypes from "prop-types"

const CloseModal = (props) => (
    <button className={[
                "absolute z-10 transform transition rotate-45 right-6 top-6",
                "hover:rotate-24 focus:outline-none"
            ].join(' ')}
            onClick={props.clickHandler}
    >
        <Plus className={["stroke-current text-shadow h-8 w-8", props.color].join(' ')} />
        <span className={"sr-only"}>Fermer</span>
    </button>
)

CloseModal.defaultProps = {
    color: `text-white`
}

CloseModal.propTypes = {
    color: PropTypes.string
}

export default CloseModal

import { useStaticQuery, graphql } from "gatsby"

const useSiteMeta = () => {
    const { site } = useStaticQuery(graphql`
        query SiteMetaData {
            site {
                siteMetadata {
                    title
                    subtitle
                    meta_title
                    description                    
                    author
                    authorUrl      
                    siteUrl   
                }
            }
        }
    `)

    return site.siteMetadata
}

export default useSiteMeta

import data from "../../../content/home.json"

import Container from "../ui/Container"
import React, {useState} from "react"
import Galery from "./Galery"
import Modal from "../ui/Modal"

const Brochure = () => {
    const [galeryOpened, setGaleryOpened] = useState(false)

    function handleClickGalery() {
        setGaleryOpened(!galeryOpened)
    }

    return (
        <aside className="bg-primary relative py-16 text-white">
            <Container css={"flex flex-col items-center justify-center space-y-8 py-2 md:flex-row md:space-y-0 md:space-x-8"}>
                {data.brochureSrc &&
                    <a
                        className={"text-center font-bold uppercase border-2 border-white px-6 py-3 leading-none hover:text-primary hover:bg-white"}
                        href={data.brochureSrc}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                    >
                        {data.brochure}
                    </a>
                }
                {data.guideSrc &&
                    <a
                        className={"text-center font-bold uppercase border-2 border-white px-6 py-3 leading-none hover:text-primary hover:bg-white"}
                        href={data.guideSrc}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                    >
                        {data.guide}
                    </a>
                }
                {data.virtualVisitSrc &&
                    <a
                        className={"text-center font-bold uppercase border-2 border-white px-6 py-3 leading-none hover:text-primary hover:bg-white"}
                        href={data.virtualVisitSrc}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                    >
                        {data.virtualVisit}
                    </a>
                }
                <button
                    className={"text-center font-bold uppercase border-2 border-white px-6 py-3 leading-none hover:text-primary hover:bg-white"}
                    onClick={() => handleClickGalery()}
                    type={"button"}
                >
                    {data.galery}
                </button>
            </Container>
            <Modal isShowing={galeryOpened} hide={() => handleClickGalery()}>
                <Galery />
            </Modal>
        </aside>
    )
}

export default Brochure

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const JapanImage = ({props}) => {
    const data = useStaticQuery(graphql`
        query {
          placeholderImage: file(relativePath: { eq: "japan.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
    `)

    if (!data?.placeholderImage?.childImageSharp?.fluid) {
        return <div>Picture not found</div>
    }

    return <Img fluid={data.placeholderImage.childImageSharp.fluid} {...props} />
}

export default JapanImage

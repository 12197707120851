import data from "../../../content/main.json"
import React from "react"

const Switcher = (props) => (
    <button
        className={[
            "flex p-1 rounded-full w-10 h-6 focus:outline-none focus:ring-2",
            props.value ? "bg-primary justify-end focus:ring-gray-200" : "bg-gray-200 focus:ring-primary",
        ].join(' ')}
        type={"button"}
        onClick={props.clickHandler || null}
    >
        <span
            className={[
                "bg-white w-4 h-4 rounded-full",
                props.value ? "" : ""
            ].join(' ')}
        />
        <span className={"sr-only"}>{data.switcher.label}</span>
    </button>
)

export default Switcher

import React, {useEffect, useState} from "react"
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import CloseModal from "./CloseModal"

const Modal = ({isShowing, hide, theme, children}) => {
    const [parentNode, setParentNode] = useState()
    useEffect(() => {
        const containerNode = document.body
        setParentNode(containerNode)
    }, [])

    return parentNode ? ReactDOM.createPortal(
        <div
            className={[
                "fixed top-0 w-full h-full z-100",
                (isShowing ? "left-0 opacity-100" : "-left-full opacity-0 pointer-events-none")
            ].join(' ')}
            aria-modal aria-hidden tabIndex={-1} role="dialog"
        >
            <CloseModal clickHandler={hide} color={theme === 'primary' ? 'text-primary' : 'text-white'} />
            {children}
        </div>, parentNode
    ) : null
}

Modal.defaultProps = {
    isShowing: false
}

Modal.propTypes = {
    isShowing: PropTypes.bool
}

export default Modal

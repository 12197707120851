import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import useSiteMeta from "../hooks/useSiteMeta"

function SEO({ description, lang, meta, title }) {
    const data = useSiteMeta()

    const metaDescription = description || data.description
    const defaultTitle = false //[data.title, data.subtitle].join(' ')
    const ogImageUrl = data.siteUrl + "/images/og-image.jpg"
    console.log('ogImageUrl', ogImageUrl)

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: data.author || ``,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: ogImageUrl,
                },
                {
                    property: `twitter:image`,
                    content: ogImageUrl,
                },
                {
                    property: `image`,
                    content: ogImageUrl,
                }
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `fr`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import Header from "./Header"
import Footer from "./Footer"
import {CookieConsentProvider} from "./cookie/CookieConsent"
import A from "../../assets/vectors/A.svg"

const Layout = ({ children }) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 1000)

    }, [])

    return (
        <CookieConsentProvider>
            <div id="loader"
                 className={[
                     "fixed z-80 inset-0 bg-gray-100 transition duration-300 flex items-center justify-center pointer-events-none",
                     (loaded ? 'opacity-0' : 'opacity-100')
                 ].join(' ')}
                 aria-hidden={"true"}
            >
                <A className={"fill-current text-primary w-64 animate-pulse"} />
            </div>
            <div className="bg-white font-normal antialiased overflow-hidden">
                <Header />
                <main className={"leading-relaxed"}>
                    {children}
                </main>
                <Footer />
            </div>
        </CookieConsentProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

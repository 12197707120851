import Galery from "../../../assets/vectors/galery.svg"
import Contact from "../../../assets/vectors/contact.svg"
import Linkedin from "../../../assets/vectors/in.svg"
import data from "../../../../content/nav/secondary.json"

export const NavbarIconItems = [
    {
        dataName: Galery,
        className: "stroke-current w-6 h-6",
        to: null,
        target: null,
        rel: null,
        modal: "galery",
        title: data[0].name
    },
    {
        dataName: Contact,
        className: "stroke-current w-6 h-6",
        to: null,
        target: null,
        rel: null,
        modal: "contact",
        title: data[1].name
    },
    {
        dataName: Linkedin,
        className: "fill-current w-6 h-6",
        to: data[2].to,
        target: '_blank',
        rel: 'noreferrer noopener',
        modal: null,
        title: data[2].name
    }
]


import React, {useState} from "react"
import {useTrail, animated} from "react-spring"
import { NavbarIconItems } from "./NavbarIconItems"
import Modal from "../../ui/Modal"
import Contact from "../../content/Contact"
import Galery from "../../content/Galery"

const NavbarIcons = (props) => {
    const css = "bg-white text-black shadow rounded-full border-2 border-transparent hover:border-white hover:shadow-xl"
    const items = NavbarIconItems

    const [galeryOpened, setGaleryOpened] = useState(false)
    const [contactOpened, setContactOpened] = useState(false)

    function handleClick(modal) {
        if( modal === "contact") {
            setContactOpened(!contactOpened)
        }
        if( modal === "galery") {
            setGaleryOpened(!galeryOpened)
        }
    }

    const trail = useTrail(items.length, {
        opacity: props.navbarOpen ? 1 : 0,
        transform: props.navbarOpen ? 'translateX(0)' : 'translateX(-3rem)',
        marginLeft: props.navbarOpen ? 21 : -21,
        from: { marginLeft: -21, opacity: 0, transform: 'translateX(-3rem)' },
    })

    return (
        <>
            <ul className={"flex items-center justify-center"}>
                {trail.map((props, index) => {
                    const elem = items[index]
                    const RenderChild = elem.dataName
                    const LinkTag = elem.to ? `a` : `button`
                    return (
                        <animated.li
                            key={index}
                            style={props}
                            className={css}
                        >
                            <LinkTag href={elem.to ? elem.to : null}
                                     className={"block p-2 hover:text-primary focus:outline-none"}
                                     target={elem.target ? elem.target : null}
                                     rel={elem.rel ? elem.rel : null}
                                     onClick={() => handleClick(elem.modal)}
                            >
                                <RenderChild className={[elem.className].join(' ')} />
                                <span className={"sr-only"}>{elem.title}</span>
                            </LinkTag>
                        </animated.li>
                    )
                })}
            </ul>
            <Modal isShowing={contactOpened} hide={() => handleClick("contact")}>
                <Contact />
            </Modal>
            <Modal isShowing={galeryOpened} hide={() => handleClick("galery")}>
                <Galery />
            </Modal>
        </>
    )
}

export default NavbarIcons

import React from "react"
import Container from "../ui/Container"
import useSiteMeta from "../../hooks/useSiteMeta"
import TactileLogo from '../../assets/vectors/tactile-logo.svg'
import Brochure from "../content/Brochure"
import Australia from "../../assets/vectors/australia.svg"
import Modal from "../ui/Modal"
import Legal from "../content/Legal"
import legalData from "../../../content/legal.json"
import accessData from "../../../content/accessible.json"
import consentData from "../../../content/main.json"
import useModal from "../../hooks/useModal"
import {useCookieConsentDispatch} from "./cookie/CookieConsent"

const Footer = () => {
    const dispatch = useCookieConsentDispatch()
    const data = useSiteMeta()
    const sep = '•'
    const {isShowing, toggle} = useModal()

    const SiteName = (
        <span className="uppercase">© {data.title} {new Date().getFullYear()}</span>
    )

    const TactileLink = (
        <span className={"flex items-center space-x-1"}>
            <span>Design by</span>
            <a href={data.authorUrl}
               className={"hover:text-black"}
               title="Aller vers le site de l'agence Tactile"
               target="_blank"
               rel="noreferrer noopener"
            >
                <TactileLogo />
            </a>
        </span>
    )

    return (
        <footer className="relative bg-gray-100">
            <Brochure />
            <Container css={"flex flex-col space-y-12 md:space-y-0 pt-8 pb-10 text-gray-500 lg:flex-row lg:items-end lg:justify-between"}>
                <div className={"md:pb-1 md:-mb-px"}>
                    <Australia className={"fill-current w-full sm:w-64"} />
                    <a
                        href={accessData.url}
                        target={"_blank"}
                        rel={"noopener noreferrer"}
                        title={"Voir sur Google Maps"}
                        className={"block transform mb-px -translate-y-px text-xs hover:text-black hover:underline"}
                    >
                        {data.subtitle}
                    </a>
                </div>
                <div className={"flex text-xs flex-col md:items-center md:flex-row lg:flex-none space-y-2 md:space-y-0 md:space-x-1"}>
                    {SiteName}
                    <span>
                        <span className={"hidden md:inline"}>{` ${sep} `}</span>
                        {`Tous droits réservés`}
                        {` ${sep} `}
                        <button
                            className={"hover:text-black hover:underline"}
                            onClick={toggle}
                        >
                            {legalData.title}
                        </button>
                        {` ${sep} `}
                        <button
                            className={"hover:text-black hover:underline"}
                            onClick={() => dispatch({type: 'showManageCookiePopup'})}
                        >
                            {consentData.consent.customize.menuTitle}
                        </button>
                        <span className={"hidden md:inline"}>{` ${sep} `}</span>
                    </span>
                    {TactileLink}
                </div>
            </Container>
            <Modal isShowing={isShowing} hide={toggle}>
                <Legal />
            </Modal>
        </footer>
    )
}

export default Footer
